import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import HeroNoImage from "../../microcomponents/heronoimage/heronoimage"
import Container from "../../microcomponents/container/container"
import PricingBox from "../../microcomponents/pricingbox/pricingbox"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import HeadSubhead from "../../microcomponents/headsubhead/headsubhead"
import InclusiveCard from "../../microcomponents/inclusivecard/inclusivecard"
import { pricinginclusions } from "../../utilities/pricinginclusions"
export default function PricingV2() {
  const TabsComponent = () => {
    return (
      <div
        className="full filters-wrapper"
        style={{
          overflowX: "hidden",
        }}
      ></div>
    )
  }

  return (
    <div>
      <HeaderV2 version="v2" />

      <HeroNoImage
        className="honey blueeclipse"
        heading="Pricing"
        description="<p>Choose one of our three <u data-hash='allinclusive' class='hashme'>all-inclusive</u> pricing models.</p>"
        component={<TabsComponent />}
      />

      <Container className="pricing-boxes-wrapper pd-40-bottom">
        <div className="pricing-boxes-inner">
          <PricingBox
            subHeading="Flat Fee"
            subHeadingDescription="Simplicity & budgeting certainty"
            price="$25k/month"
          >
            <p>
              Simple month-to-month pricing.
              <br className="" />
              Won't confuse finance or legal.
              <br />
              No commitment.
            </p>
            <Button version={`v2`} href={`/get-demo/`} icon={<ArrowIcon />}>
              Get a Demo
            </Button>
          </PricingBox>
          <PricingBox
            subHeading="Performance"
            subHeadingDescription="Pay only after you see results"
            price="Contact"
          >
            <p>
              Nothing upfront.
              <br />
              Fees tied 100% to test uplift.
              <br />
              You are the sole judge.
            </p>
            <Button version={`v2`} href={`/get-demo/`} icon={<ArrowIcon />}>
              Get a Demo
            </Button>
          </PricingBox>
          <PricingBox
            subHeading="Pilot"
            subHeadingDescription="A quick proof of concept"
            price="Contact"
          >
            <p>
              Test on a small set of high-value <br />
              pages. Get quick wins and <br />
              create organizational buy-in.
            </p>
            <Button version={`v2`} href={`/get-demo/`} icon={<ArrowIcon />}>
              Get a Demo
            </Button>
          </PricingBox>
        </div>
      </Container>
      <ClientLogos version="v2" />

      <Container
        className="all-inclusive-wrapper gray-bg pd-112"
        id="allinclusive"
      >
        <HeadSubhead
          version={`v2`}
          heading={`<span style="font-weight:600;text-transform:none;letter-spacing:-0.96px">All-inclusive pricing</span>`}
          subheading={`Our fees include everything you need to get results. No hidden extras.`}
        ></HeadSubhead>
        <div className="all-inclusive-inner">
          {pricinginclusions.map((inclusion, index) => {
            return <InclusiveCard key={index} {...inclusion} />
          })}
        </div>
      </Container>

      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
