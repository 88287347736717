export const pricinginclusions = [
  {
    title: "Prediction Engine",
    description:
      "Harness the collective wisdom of all A/B tests that run across 78,000 sites online. The engine finds proven winners for your site.",
    image: "frame_1171275309.webp",
    imageName: [
      {
        imageName: "frame_1171275309.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275314.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275320.webp",
        minBreakpoint: "250px",
      },
    ],
  },
  {
    title: "Design",
    description:
      "World-class design that is conversion-centric and  on-brand. Get tests that everyone wants to win. Desktop, mobile, and responsive layouts.",
    image: "frame_1171275308.webp",
    imageName: [
      {
        imageName: "frame_1171275308.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275331.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275350.webp",
        minBreakpoint: "250px",
      },
    ],
  },
  {
    title: "Copy",
    description:
      "Captivate your target audience with high-converting copy patterns backed by data. Written in your voice and on-brand.",
    image: "frame_1171275326.webp",
    imageName: [
      {
        imageName: "frame_1171275326.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275316.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275322.webp",
        minBreakpoint: "250px",
      },
    ],
  },
  {
    title: "Development & QA",
    description:
      "Tests are built for all devices. Rigorous 10-phase QA process includes machine and human testing. Go from idea to live test in 1-2 weeks.",
    image: "frame_1171275330.webp",
    imageName: [
      {
        imageName: "frame_1171275330.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275317.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275352.webp",
        minBreakpoint: "250px",
      },
    ],
  },
  {
    title: "Testing Tools",
    description:
      "A/B testing tools (VWO & Mutiny) are provided. Plus, heatmapping (CrazyEgg & Hotjar), message testing (Wynter), and anything else you need.",
    image: "frame_1171275312.webp",
    imageName: [
      {
        imageName: "frame_1171275312.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275319.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275324.webp",
        minBreakpoint: "250px",
      },
    ],
  },
  {
    title: "Hard Coding",
    description:
      "Winners are hard-coded natively in your CMS. If you prefer to do the hard coding, we provide detailed specifications and design assets.",
    image: "frame_1171275311.webp",
    imageName: [
      {
        imageName: "frame_1171275311.webp",
        minBreakpoint: "1025px",
      },
      {
        imageName: "frame_1171275318.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "frame_1171275325.webp",
        minBreakpoint: "250px",
      },
    ],
  },
]
