import React from "react"
import "./pricingbox.scss"
import { PricingPolygon, PricingPolygonTablet } from "../icons"

export default function PricingBox({
  subHeading = "",
  subHeadingDescription = "",
  price = "",
  children,
}) {
  return (
    <div className="pricing-boxes-polygon">
      <PricingPolygon />
      <PricingPolygonTablet />
      <div className="pricing-box">
        <h3>{subHeading}</h3>
        <p>{subHeadingDescription}</p>
        <h2>{price}</h2>
        <div className="pricing-box-description">{children}</div>
      </div>
      <div className="tablet-pricing-box-description hidedesktop hidemobile">
        {children}
      </div>
    </div>
  )
}
