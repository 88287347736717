import React from "react"
import Image from "../../components/image-component/image-new"
import "./inclusioncard.scss"

export default function InclusiveCard({
  title = "Title",
  description = "Description",
  image = "",
  imageName = [],
}) {
  return (
    <div className="inclusion-card">
      <Image
        sourceFolder={"spiralyze2024website"}
        lazyLoad={"false"}
        alt={title}
        imgName={imageName}
        fallBackImage={image}
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}
