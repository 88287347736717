import * as React from "react"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import PricingV2 from "../componentsv2/pricingv2/pricingv2"
const Pricing = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Pricing" />
    <PricingV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default Pricing
